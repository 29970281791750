import React, { useState, useEffect } from "react";

export default function FacultyOfEnggTech() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const facultyData = [
    {
      name: "Dr. Dhiraj Pandey",
      designation: "Asso.Prof",
      department: "Computer Science and Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Dr_Dheeraj_Pandey_JSS_University.pdf"
    },

    {
      name: "Dr. Jaspreet Kaur",
      designation: "Asst.Prof",
      department: "Computer Science and Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Dr_Jaspreet_kaur_JSS_University.docx"
    },
    {
      name: "Mr. Vinooth P",
      designation: "Asst.Prof",
      department: "Computer Science and Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Mr_Vinooth_JSS_University.pdf"
    },
    {
      name: "Dr. Anuranjan Kansal",
      designation: "Asst.Prof",
      department: "Electronics and Communication Engineering",
      profile: "#" //"https://jssuninoida.edu.in/assets/profiles/Mr_Anuranjan_JSS_University.docx"
    },
    {
      name: "Dr. Gayatri Sakya",
      designation: "Asso.Prof",
      department: "Electronics and Communication Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Dr_Gaytri_Shakhya_profile_for_JSS_University.docx"
    },
    {
      name: "Mr. Ganesha H S",
      designation: "Asst.Prof",
      department: "Electronics and Communication Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Mr_Ganesha_JSS_University.pdf"
    },
    {
      name: "Mr. Veerendra Kumar A N",
      designation: "Asst.Prof",
      department: "Mechanical Engineering",
      profile: "#" //"https://jssuninoida.edu.in/assets/profiles/Mr_AN_Veerendra_JSS_University.docx"
    },
    {
      name: "Mr. Naveen J",
      designation: "Asst.Prof",
      department: "Mechanical Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Mr_Naveen_J _JSS_University_Profile.pdf"
    },
    {
      name: "Dr. Gunjan Varshney",
      designation: "Asso.Prof",
      department: "Robotics and Artificial Intelligence",
      profile: "https://jssuninoida.edu.in/assets/profiles/Dr_Gunjan_Varsney_JSS_University_profile.docx"
    },
    {
      name: "Mr. Amit Tripathi",
      designation: "Asst.Prof",
      department: "Civil Engineering",
      profile: "https://jssuninoida.edu.in/assets/profiles/Amit_Tripathi_JSS_University.pdf"
    },
  ];

  return (
    <div
      style={{
        padding: "20px",
        marginTop: windowWidth < 768 ? "50px" : "300px",
        marginBottom: "50px",
      }}
    >
      <h1>Faculty Of Engineering</h1>
      <p>
        Our faculty members are highly experienced and industry-oriented,
        ensuring that students receive the best education and practical
        knowledge.
      </p>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ background: "#0054a4", color: "#fff" }}>
            <tr>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Name
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Designation
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Department
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {facultyData.map((faculty, index) => (
              <tr key={index} style={{ textAlign: "left" }}>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.name}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.designation}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.department}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  
                  <a 
                  className="btn btn-primary" 
                  target="_blank" 
                  href={faculty.profile}>View Profile</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
