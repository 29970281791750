import React, { useState } from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const images = [
  { src: "/images/inaugeration/1.jpeg", alt: "Image 1" },
  { src: "/images/inaugeration/2.jpeg", alt: "Image 2" },
  { src: "/images/inaugeration/3.jpeg", alt: "Image 3" },
  { src: "/images/inaugeration/4.jpeg", alt: "Image 4" },
  { src: "/images/inaugeration/5.jpeg", alt: "Image 5" },
];

const videos = [
  { src: "https://www.youtube.com/embed/dQw4w9WgXcQ", alt: "Video 1" },
  { src: "https://www.youtube.com/embed/dQw4w9WgXcQ", alt: "Video 2" },
  { src: "https://www.youtube.com/embed/dQw4w9WgXcQ", alt: "Video 3" },
];

export default function PhotoGallery() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <section
      className="section"
      style={{
        position: "relative",
        top: "185px",
        marginBottom: "100px",
        padding: "50px",
      }}
    >
      <center>
        <h1>Photo and Video Gallery</h1>
      </center>
      <div
        className="gallery"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "120px",
        }}
      >
        {/* <br />
        <br />

        <h2>Video Gallery</h2>
        <CarouselProvider
          naturalSlideWidth={1000}
          naturalSlideHeight={600}
          totalSlides={videos.length}
          isPlaying
          interval={5000}
          style={{
            width: "100%",
            maxWidth: "1000px",
            marginBottom: "20px",
          }}
        >
          <Slider>
            {videos.map((video, index) => (
              <Slide key={index + images.length} index={index + images.length}>
                <iframe
                  src={video.src}
                  title={video.alt}
                  width="100%"
                  height="600"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Slide>
            ))}
          </Slider>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "1000px",
              marginTop: "10px",
            }}
          ></div>
        </CarouselProvider> */}
        <br />
        <br />
        <h2>Image Gallery</h2>
        <CarouselProvider
          naturalSlideWidth={1000}
          naturalSlideHeight={600}
          totalSlides={images.length}
          isPlaying
          interval={5000}
          style={{
            width: "100%",
            maxWidth: "1000px",
            marginBottom: "20px",
          }}
        >
          <Slider>
            {images.map((image, index) => (
              <Slide key={index} index={index}>
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{ width: "100%", height: "auto" }}
                />
              </Slide>
            ))}
          </Slider>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "1000px",
              marginTop: "10px",
            }}
          >
            <ButtonBack className="btn btn-primary">Back</ButtonBack>
            <ButtonNext className="btn btn-primary">Next</ButtonNext>
          </div>
        </CarouselProvider>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
          }}
        >
          Welcome to our Photo Gallery! Here you can find a collection of images
          and videos showcasing our latest projects and events.
        </p>
      </div>
    </section>
  );
}
