import React from "react";
import Banner from "./components/Banner";
import Campusvideo from "./components/Campusvideo";
import HomepageCarousel from "./components/HomepageCarousel";
import Newssection from "./components/Newssection";
import Recruiter from "./components/Recruiter";
import Courssection from "./components/Courssection";
import { useState, useEffect } from "react";

export default function Homepage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(true);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <HomepageCarousel></HomepageCarousel>
      <Courssection></Courssection>
      {/* <Banner></Banner> */}
      <Campusvideo></Campusvideo>
      {/* <Recruiter></Recruiter> */}
      {showModal && (
        <div
          className="modal"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              maxWidth: "800px",
              margin: "10px",
              maxHeight: "90vh",
            }}
          >
            <div className="modal-header">
              <h3 className="modal-title">Admission open for JSS University</h3>
              <button onClick={toggleModal} className="close">
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <center>
                <img
                  src="images/poster_admission.jpg"
                  alt=""
                  style={{
                    objectFit: "fit",
                    maxWidth: "100%",
                    marginBottom: "20px",
                    marginRight: "20px",
                  }}
                />
              </center>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
