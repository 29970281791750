import React from "react";
import { useState, useEffect } from "react";

export default function FeeStructure() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <section
      className="section"
      style={{
        marginTop: windowWidth > 768 ? "30vh" : "100px",
        marginBottom: "100px",
        padding: "10px",
        fontSize: windowWidth > 768 ? "1.2em" : "0.75em",
      }}
      >
        <center>
          <h2>Fee Structure 2024-25</h2>
        </center>

          <table className="table table-bordered" style={{width:'100vw'}}>
            <caption>
             Fee structure for the session 2024-25
            </caption>
            <tbody>
              <tr>
                <td rowspan="2"><b>Branch</b></td>
                <td colspan="4"><b>Fee for AY 2024-25</b></td>
              </tr>
                <tr>
                    <td><b>Year 1</b></td>
                    <td><b>Year 2</b></td>
                    <td><b>Year 3</b></td>
                    <td><b>Year 4</b></td>
                </tr>
                <tr>
                    <td>CSE</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                </tr>
                <tr>
                    <td>CSE (AIML)</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                </tr>
                <tr>
                    <td>IT</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                </tr>
                <tr>
                    <td>CSE (DS)</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000</td>
                </tr>
                <tr>
                    <td>ECE</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                </tr>
                <tr>
                    <td>ROBOTICS AND AI</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                    <td>Rs. 2,00,000</td>
                </tr>
                <tr>
                    <td>MCA</td>
                    <td>Rs. 1,75,000</td>
                    <td>Rs. 1,75,000</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>MBA Finance</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>MBA General</td>
                    <td>Rs. 2,50,000</td>
                    <td>Rs. 2,50,000</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Science Course (M.Sc Physics or Chemistry or Maths)</td>
                    <td>Rs. 75,000</td>
                    <td>Rs. 75,000</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>B. Pharm</td>
                    <td>Rs. 1,55,000</td>
                    <td>Rs. 1,55,000</td>
                    <td>Rs. 1,55,000</td>
                    <td>Rs. 1,55,000</td>
                </tr>
                <tr>
                    <td>D. Pharm</td>
                    <td>Rs. 60,000</td>
                    <td>Rs. 60,000</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>M. Pharm</td>
                    <td>Rs. 2,25,000</td>
                    <td>Rs. 2,25,000 </td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
          </table>

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "10px",
            marginBottom: "120px",
          }}
        >
          <ul style={{listStyle:'disc'}}>
            <li>In addition, a sum of Rs.2, 000/- towards registration fee and Rs. 10,000/-towards Security Deposit (refundable) will be collected at the time of admission. </li>
            <li>An additional mandatory Examination Fee of Rs. 7,500/- per annum
 ( For both the semesters) </li>
          </ul>
          </p>
      </section>
    </>
  );
}
