import React, { useEffect, useState } from "react";
import Notification from "./Homepage/components/Notifications";

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDropdown = (event, level) => {
    if (isMobile) {
      event.preventDefault();
      const currentTarget = event.currentTarget;
      const submenu = currentTarget.nextElementSibling;

      if (submenu) {
        const isCurrentlyActive = submenu.classList.contains("show");

        // Close all other open dropdowns at the same level
        const allDropdowns = document.querySelectorAll(
          `.dropdown-menu.show.level-${level}`
        );
        allDropdowns.forEach((dropdown) => {
          if (dropdown !== submenu) {
            dropdown.classList.remove("show");
          }
        });

        // Toggle the clicked dropdown
        submenu.classList.toggle("show");

        // If this dropdown was just opened, close any open child dropdowns
        if (!isCurrentlyActive) {
          const childDropdowns = submenu.querySelectorAll(
            ".dropdown-menu.show"
          );
          childDropdowns.forEach((childDropdown) => {
            childDropdown.classList.remove("show");
          });
        }
      }
    }
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          top: windowWidth > 768 ? "50px" : "0px",
        }}
      >
        <header class="fixed-top header">
          <Notification></Notification>
          {windowWidth > 768 ? (
            <div class="top-header py-2 bg-white">
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-lg-4 text-center text-lg-left">
                    <a class="text-color mr-3" href="tel:+9101202401442">
                      <strong>CALL</strong> +91-0120-2401442
                    </a>
                    <ul class="list-inline d-inline">
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-linkedin"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-lg-8 text-center text-lg-right"
                    style={{ float: "right" }}
                  >
                    <ul class="list-inline">
                      {/* <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          notice
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          research
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          SCHOLARSHIP
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          Brochure
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              class="py-2 bg-white"
              style={{
                minHeight: "15px",
              }}
            >
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-lg-4 text-center text-lg-left">
                    <a class="text-color mr-3" href="tel:+9101202401442">
                      <strong>CALL</strong> +91-0120-2401442
                    </a>
                    <ul class="list-inline d-inline">
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-linkedin"></i>
                        </a>
                      </li>
                      <li class="list-inline-item mx-0">
                        <a class="d-inline-block p-2 text-color" href="#">
                          <i class="ti-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="col-lg-8 text-center text-lg-right"
                    style={{ float: "right" }}
                  >
                    <ul class="list-inline">
                      {/* <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          notice
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          research
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          SCHOLARSHIP
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="text-uppercase text-color p-sm-2 py-2 px-0 d-inline-block"
                          href="/page-under-development"
                        >
                          Brochure
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          {windowWidth > 768 ? (
            <div class="navigation w-100">
              {/* <div class="container"> */}
              <nav
                class="navbar navbar-expand-lg navbar-light p-0"
                style={{ height: "120px" }}
              >
                <a
                  style={{
                    maxWidth: "35%",
                    color: "#fff",
                    fontWeight: "bolder",
                    padding: "5px 30px",
                    fontSize: "1.3em",
                  }}
                  href="/"
                  className="navbar-brand"
                >
                  <img
                    src="./images/logo.png"
                    alt="logo"
                    style={{
                      maxHeight: "85px",
                      maxWidth: "85px",
                      margin: "0px 15px",
                      // position: "relative",
                      // left:"100px",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      lineHeight: "1em",
                      verticalAlign: "middle",
                    }}
                  >
                    JSS
                    <br />
                    UNIVERSITY
                  </div>
                </a>

                <button
                  class="navbar-toggler rounded-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  style={{
                    // position: "relative",
                    // right:"150px"
                    fontSize: "1.2em",
                  }}
                  id="navigation"
                >
                  <ul class="navbar-nav mr -auto text-center">
                    <li class="nav-item dropdown view" id="myDropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        {" "}
                        About{" "}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-jssmvp">
                            {" "}
                            About JSSMVP
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-university">
                            {" "}
                            About University
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="/vision-mission-quality-policy"
                          >
                            {" "}
                            Vision and Mission, Quality Policy
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Leadership &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/chancellor">
                                Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-seceratory"
                              >
                                Executive Secretary
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/about-pro-chancellor"
                              >
                                Pro Chancellor
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="dropdown"
                          >
                            {" "}
                            Authorities &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/governing-bodies">
                                Governing Body (GB)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-council"
                              >
                                Executive Council (EC)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Academic Council
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/BOS.pdf"
                                target="_blank"
                              >
                                Board of Studies (BoS)
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Administration &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/administration-vice-chancellor"
                              >
                                Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Registrar
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/controller-of-examination"
                              >
                                Controller of Examination (CoE)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Deans
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Special Officer
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Financial Officer
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Committees &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Anti Ragging Committee
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Grievance Redressal Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Student Counselling Cell
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                College Internal Complaint Committee
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                SC/ST/OBC Cell
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            Reports and Downloads &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Approval Letter
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="#">
                            {" "}
                            University Green Initiatives &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/green-policy">
                                Green Policy
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sewage-treatment-plant"
                              >
                                Sewage Treatment Plant (STP)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rooftop-solar-plant"
                              >
                                Roof Top Solar Power Plant
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rainwater-harvesting"
                              >
                                Rainwater Harvesting
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sustainable-waste-management"
                              >
                                Sustainable Solid Waste Management
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/tree-plantation">
                                Gardening & Tree Planting Information
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/employee-benefits">
                            {" "}
                            Employee Benefits
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/statutory-bodies">
                            Approval from Statutory bodies
                          </a>
                        </li>
                        {/* <li>
                          {" "}
                          <a class="dropdown-item" href="/about-university">
                            {" "}
                            Messages &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/chancellor-message"
                              >
                                Message from Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/pro-chancellor-message"
                              >
                                Message from Pro Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/vice-chancellor-message"
                              >
                                Message from Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Message from Registrar
                              </a>
                            </li>
                          </ul>
                        </li> */}
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Admission
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="/school-of-engineering"
                          >
                            Under Graduate Program &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (DATA SCIENCE)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in INFORMATION TECHNOLOGY</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ELECTRONICS AND COMMUNICATION ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ROBOTICS AND ARTIFICIAL INTELLIGENCE</a>
                          </li>
                        </ul> */}
                        </li>
                        <li>
                          <a class="dropdown-item" href="/school-of-management">
                            Post Graduate Program &raquo;
                          </a>
                        </li>
                        {/* <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Research Program
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Prospectus
                          </a>
                        </li> */}
                        <li>
                          <a
                            class="dropdown-item"
                            href="/admission-helpdesk-contact"
                          >
                            Admission Help Desk & Contact Details
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                      >
                        Academics
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="#">
                            Faculty &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-engg-tech"
                              >
                                Faculty of Engineering & Technology
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-science"
                              >
                                Faculty of Science
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-humanities"
                              >
                                Faculty of Humanities
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Management Studies
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Pharmacy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/JSSUN-2024-1st-Sem-Calendar-of%20Events-V2.pdf"
                            target="_blank"
                          >
                            Calendar of Events
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Syllabus
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Exam Schedule
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/TIME_TABLE.pdf"
                            target="_blank"
                          >
                            Time Table
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="#">
                            Professional Bodies &raquo;
                          </a>
                          <ul class="submenu dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="/csi-detail">
                                CSI
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sae-detail">
                                SAE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iete-detail">
                                IETE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iste-detail">
                                ISTE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/ieee-detail">
                                IEEE
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Industry Collaboration & MoUs
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            University News Letter
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Facilities
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          class="dropdown-item"
                          href="/library-information-centre"
                        >
                          Library & Information Centre
                        </a>
                        <a class="dropdown-item" href="/hostel-facilities">
                          Hostel
                        </a>
                        <a class="dropdown-item" href="/sports-facilities">
                          Sports
                        </a>
                        <a class="dropdown-item" href="/medical-facilities">
                          Medical Centre
                        </a>
                        <a class="dropdown-item" href="/amenities-center">
                          Amenities Centre
                        </a>
                        <a class="dropdown-item" href="/guest-house">
                          Guest House
                        </a>
                        <a class="dropdown-item" href="/bank-atm">
                          Bank ATM
                        </a>
                        <a class="dropdown-item" href="/canteen">
                          Canteen
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Student Support
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/scholarships">
                          Scholarships
                        </a>
                        <a class="dropdown-item" href="/mentoring">
                          Mentoring Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Fee Payment
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          NPTEL
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Infosys Springboard
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Cultural Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Student Clubs
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Accidental Insurance Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Meritortious Awards
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Research & Innovation
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/research-facilities">
                          Research Centres and Facilities
                        </a>
                        {/* <a class="dropdown-item" href="/page-under-development">
                          Publication, Patents
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Research Policy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          CoEs
                        </a> */}
                        <a class="dropdown-item" href="/step-info">
                          STEP and Startups in Campus
                        </a>
                        {/* <a class="dropdown-item" href="/page-under-development">
                          Consultancy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Events/Activities
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Cultural Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Funding
                        </a> */}
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link "
                        href="/placement-statistics"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Training & Placement
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Policy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Statistics
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Training
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Internship
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Contact Form for Companies
                        </a>
                      </div> */}
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/iqac"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        IQAC
                      </a>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a class="dropdown-item" href="/photo-gallery">
                            Photo Gallery
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Online Grievance
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/media-coverage">
                            Media Coverage
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Career Oppurtunity
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/admission-helpdesk-contact"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Contact Us
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Alumni
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Photo Gallery
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Online Grievance System
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Media Coverage
                        </a>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </nav>
              {/* </div> */}
            </div>
          ) : (
            <div
              class="navigation w-100"
              style={{
                marginTop: "-46px !important",
              }}
            >
              {/* <div class="container"> */}
              <nav class="navbar navbar-expand-lg navbar-light p-0">
                <a
                  style={{
                    maxWidth: "30%",
                    color: "#fff",
                    fontWeight: "bolder",
                    padding: "5px 30px",
                  }}
                  href="/"
                  className="navbar-brand"
                >
                  <img
                    src="./images/logo.png"
                    alt="logo"
                    style={{
                      maxHeight: "60px",
                      maxWidth: "60px",
                      margin: "0px 15px",
                      // position: "relative",
                      // left:"100px",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      lineHeight: "1em",
                      verticalAlign: "middle",
                    }}
                  >
                    JSS
                    <br />
                    UNIVERSITY
                  </div>
                </a>

                <button
                  class="navbar-toggler rounded-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="navigation"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse"
                  style={
                    {
                      // position: "relative",
                      // right:"150px"
                    }
                  }
                  id="navigation"
                >
                  <ul class="navbar-nav mr -auto text-center">
                    <li class="nav-item dropdown view" id="myDropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        onClick={(e) => toggleDropdown(e, 1)}
                      >
                        {" "}
                        About{" "}
                      </a>
                      <ul class="dropdown-menu level-1">
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/about-jssmvp">
                            {" "}
                            About JSSMVP
                          </a>
                        </li>
                        <li>
                          <li>
                            {" "}
                            <a class="dropdown-item" href="/about-university">
                              {" "}
                              About University
                            </a>
                          </li>
                          <li>
                            {" "}
                            <a
                              class="dropdown-item"
                              href="/vision-mission-quality-policy"
                            >
                              {" "}
                              Vision and Mission, Quality Policy
                            </a>
                          </li>{" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Leadership &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/chancellor">
                                Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-seceratory"
                              >
                                Executive Secretary
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/about-pro-chancellor"
                              >
                                Pro Chancellor
                              </a>
                            </li>
                          </ul>
                        </li>
                        {/* <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="/about-university"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Messages &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/chancellor-message"
                              >
                                Message from Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/pro-chancellor-message"
                              >
                                Message from Pro Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/vice-chancellor-message"
                              >
                                Message from Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Message from Registrar
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="dropdown"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Authorities &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/governing-bodies">
                                Governing Body (GB)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/executive-council"
                              >
                                Executive Council (EC)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Academic Council
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="https://jssuninoida.edu.in/assets/docs/BOS.pdf"
                                target="_blank"
                              >
                                Board of Studies (BoS)
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Administration &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/administration-vice-chancellor"
                              >
                                Vice Chancellor
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/registrar-message"
                              >
                                Registrar
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/controller-of-examination"
                              >
                                Controller of Examination (CoE)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Deans
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Special Officer
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Financial Officer
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            Reports and Downloads &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Approval Letter
                              </a>
                            </li>
                            {/* <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Acts, Ordinances and Statutes
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Propectus
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Human Resource Mannual
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                University Annual Reports
                              </a>
                            </li> */}
                          </ul>
                        </li>

                        <li>
                          {" "}
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            {" "}
                            University Green Initiatives &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/green-policy">
                                Green Policy
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sewage-treatment-plant"
                              >
                                Sewage Treatment Plant (STP)
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rooftop-solar-plant"
                              >
                                Roof Top Solar Power Plant
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/rainwater-harvesting"
                              >
                                Rainwater Harvesting
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/sustainable-waste-management"
                              >
                                Sustainable Solid Waste Management
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/tree-plantation">
                                Gardening & Tree Planting Information
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          <a class="dropdown-item" href="/employee-benefits">
                            {" "}
                            Employee Benefits
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Gratuity
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Group Personal Accident Policy
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Maternity Benefit
                              </a>
                            </li>
                          </ul> */}
                        </li>
                        <li>
                          <a class="dropdown-item" href="/statutory-bodies">
                            Approval from Statutory bodies
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Admission
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a
                            class="dropdown-item"
                            href="/school-of-engineering"
                          >
                            Under Graduate Program &raquo;
                          </a>
                          {/* <ul class="submenu dropdown-menu">
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in COMPUTER SCIENCE AND ENGINEERING (DATA SCIENCE)</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in INFORMATION TECHNOLOGY</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ELECTRONICS AND COMMUNICATION ENGINEERING</a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">B.Tech in ROBOTICS AND ARTIFICIAL INTELLIGENCE</a>
                          </li>
                        </ul> */}
                        </li>
                        <li>
                          <a class="dropdown-item" href="/school-of-management">
                            Post Graduate Program &raquo;
                          </a>
                        </li>
                        {/* <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Research Program
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Prospectus
                          </a>
                        </li> */}
                        <li>
                          <a
                            class="dropdown-item"
                            href="/admission-helpdesk-contact"
                          >
                            Admission Help Desk & Contact Details
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        data-toggle="dropdown"
                        onClick={(e) => toggleDropdown(e, 1)}
                      >
                        Academics
                      </a>
                      <ul class="dropdown-menu level-1">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Faculty &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-engg-tech"
                              >
                                Faculty of Engineering & Technology
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-science"
                              >
                                Faculty of Science
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/faculty-of-humanities"
                              >
                                Faculty of Humanities
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Management Studies
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="/page-under-development"
                              >
                                Faculty of Pharmacy
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/JSSUN-2024-1st-Sem-Calendar-of%20Events-V2.pdf"
                            target="_blank"
                          >
                            Calendar of Events
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Syllabus
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Exam Schedule
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="https://jssuninoida.edu.in/assets/docs/TIME_TABLE.pdf"
                            target="_blank"
                          >
                            Time Table
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={(e) => toggleDropdown(e, 2)}
                          >
                            Professional Bodies &raquo;
                          </a>
                          <ul class="submenu dropdown-menu level-2">
                            <li>
                              <a class="dropdown-item" href="/csi-detail">
                                CSI
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/sae-detail">
                                SAE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iete-detail">
                                IETE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/iste-detail">
                                ISTE
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/ieee-detail">
                                IEEE
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Industry Collaboration & MoUs
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            University News Letter
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Facilities
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a
                          class="dropdown-item"
                          href="/library-information-centret"
                        >
                          Library & Information Centre
                        </a>
                        <a class="dropdown-item" href="/hostel-facilities">
                          Hostel
                        </a>
                        <a class="dropdown-item" href="/sports-facilities">
                          Sports
                        </a>
                        <a class="dropdown-item" href="/medical-facilities">
                          Medical Centre
                        </a>
                        <a class="dropdown-item" href="/amenities-center">
                          Amenities Centre
                        </a>
                        <a class="dropdown-item" href="/guest-house">
                          Guest House
                        </a>
                        <a class="dropdown-item" href="/bank-atm">
                          Bank ATM
                        </a>
                        <a class="dropdown-item" href="/canteen">
                          Canteen
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Student Support
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/scholarships">
                          Scholarships
                        </a>
                        <a class="dropdown-item" href="/mentoring">
                          Mentoring Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Fee Payment
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          NPTEL
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Infosys Springboard
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Cultural Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Student Clubs
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Accidental Insurance Scheme
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Meritortious Awards
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Research & Innovation
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/research-facilities">
                          Research Centres and Facilities
                        </a>
                        {/* <a class="dropdown-item" href="/page-under-development">
                          Publication, Patents
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Research Policy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          CoEs
                        </a> */}
                        <a class="dropdown-item" href="/step-info">
                          STEP and Startups in Campus
                        </a>
                      </div>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/placement-statistics"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Training & Placement
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Policy
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Placement Statistics
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Training
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Internship
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Contact Form for Companies
                        </a>
                      </div> */}
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/iqac"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        IQAC
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Chairman Message
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          IQAC Team
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Calendar
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Meeting Proceedings
                        </a>
                      </div> */}
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        More
                      </a>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a class="dropdown-item" href="/photo-gallery">
                            Photo Gallery
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Online Grievance
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/media-coverage">
                            Media Coverage
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="/page-under-development"
                          >
                            Career Oppurtunity
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="nav-item dropdown view">
                      <a
                        class="nav-link"
                        href="/admission-helpdesk-contact"
                        role="button"
                        // data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        Contact Us
                      </a>
                      {/* <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="/page-under-development">
                          Alumni
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Photo Gallery
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Online Grievance System
                        </a>
                        <a class="dropdown-item" href="/page-under-development">
                          Media Coverage
                        </a>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </nav>
              {/* </div> */}
            </div>
          )}
        </header>
      </div>
    </>
  );
}
